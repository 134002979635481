import { CommonModule } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { Button } from '@app/core/models/button.model';
import { Icon } from '@app/core/models/icon.model';
import { L10N_LOCALE, L10nLocale, L10nTranslationModule } from 'angular-l10n';
import { QrIconComponent } from '../../qr-icon/qr-icon.component';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'qr-button-x',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    L10nTranslationModule,
    QrIconComponent,
    MatTooltipModule,
  ],
})
export class ButtonComponent implements OnInit {
  @Input() config!: Button;
  icon: Icon = { name: Icon.NAME_PLUS, color: Icon.COLOR_BLACK };
  @Input() isDisabled: boolean = false;

  constructor(@Inject(L10N_LOCALE) public locale: L10nLocale) {}

  ngOnInit(): void {
    if (this.icon) {
      this.icon = { ...this.icon, name: this.config.icon || '' };
    }

    switch (this.config.hierarchy) {
      case Button.HIERARCHY_PRIMARY_SOLID:
        this.icon = { ...this.icon, color: Icon.COLOR_WHITE };
        break;
      case Button.HIERARCHY_TONAL_BORDER:
      case Button.HIERARCHY_TONAL:
      case Button.HIERARCHY_PRIMARY_BORDER:
        this.icon = { ...this.icon, color: Icon.COLOR_PRIMARY };
        break;
      case Button.HIERARCHY_GREY_BORDER:
      case Button.HIERARCHY_GREY:
        this.icon = { ...this.icon, color: Icon.COLOR_GREY_WOLF };
        break;
      case Button.HIERARCHY_GREEN_SOLID:
        this.icon = { ...this.icon, color: Icon.COLOR_WHITE };
        break;
      default:
        this.icon = { ...this.icon, color: Icon.COLOR_BLACK };
        break;
    }
    if (this.isDisabled) {
      this.icon = { ...this.icon, color: Icon.COLOR_GREY_IRON };
    }
  }
}
