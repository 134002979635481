export class Button {
  // Jerarquía
  public static HIERARCHY_PRIMARY_SOLID = 'button--primary-solid';
  public static HIERARCHY_PRIMARY_BORDER = 'button--primary-border';
  public static HIERARCHY_TONAL_BORDER = 'button--tonal-border';
  public static HIERARCHY_TONAL = 'button--tonal';
  public static HIERARCHY_GREY_BORDER = 'button--grey-border';
  public static HIERARCHY_GREY = 'button--grey';
  public static HIERARCHY_GREEN_SOLID = 'button--green-solid';
  // Tamaños
  public static SIZE_48_PX = 48;
  public static SIZE_40_PX = 40;
  public static SIZE_32_PX = 32;
  id?: number | string;

  hierarchy!: string;

  size!: number;

  value?: string;

  icon?: string;

  iconOnly?: boolean = false;

  // Para botones redondos en donde solo se ve el icono sin texto
  iconTooltipOnly?: string = '';

  ariaLabel?: string;

  type?: string;
}
